<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "Allocation",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss"></style>
